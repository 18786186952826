import styles from '../styles/DeliverySection.module.css'
import HomeDeliveryTime from './HomeDeliveryTime'
function Delivery() {
  return (
    <section className={styles.deliverySection}>
      <HomeDeliveryTime />
      <div className={styles.deliveryOptions}>
        <div className={styles.deliveryOption}>
          <h3 className={styles.optionTitle}>Standard Leverans</h3>
          <p className={styles.optionDetails}>Pris: 45 kr</p>
          <p className={styles.optionDetails}>Avstånd: Upp till 3 km</p>
        </div>
        <div className={styles.deliveryOption}>
          <h3 className={styles.optionTitle}>Expressleverans</h3>
          <p className={styles.optionDetails}>Pris: 90 kr</p>
          <p className={styles.optionDetails}>Avstånd: Upp till 6 km</p>
        </div>
      </div>
    </section>
  )
}

export default Delivery
