import styles from '../styles/DeliverySection.module.css'
import { useTranslation } from 'next-i18next'
import CarDelivery from './CarDelivery'
import { formatTime } from '@/utils/formatTime'
import { lastOrderDelivery, openingHours } from '@/data/timeData'

export default function HomeDeliveryTime() {
  const { t, i18n } = useTranslation()

  return (
    <section className={styles.deliveryTime}>
      <CarDelivery />
      <p className={styles.optionDetails}>
        {t('Last order for home delivery')}
      </p>
      <time
        className={styles.deliveryHour}
        dateTime={openingHours['MON-FRI'].open}
      >
        {formatTime(lastOrderDelivery['ALL-DAYS'], i18n.language)}
      </time>
    </section>
  )
}
