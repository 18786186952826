import React, { useEffect } from 'react'
import styles from '../styles/DeliverySection.module.css'
const CarDelivery = () => {
  useEffect(() => {
    // Function to handle the intersection observer callback
    function handleIntersection(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add the animation class to the SVG element
          entry.target.classList.add('animateSvg')
          // Disconnect the observer once animation is triggered
          observer.disconnect()
        }
      })
    }

    // Create an intersection observer instance
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    })

    // Select the SVG element
    const svgElement = document.getElementById('svg-icon')

    // Start observing the SVG element
    observer.observe(svgElement)
  })
  return (
    <div className={styles.svgContainer}>
      <svg
        className='svg'
        width='90'
        height='50'
        viewBox='0 0 45 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        id='svg-icon'
      >
        <g id='Frame 12'>
          <g id='lightGroup'>
            <path
              id='light'
              d='M29.375 16.75H28.75V12.5273C28.75 12.0312 28.5508 11.5547 28.1992 11.2031L24.2969 7.30078C23.9453 6.94922 23.4688 6.75 22.9727 6.75H21.25V4.875C21.25 3.83984 20.4102 3 19.375 3H9.375C8.33984 3 7.5 3.83984 7.5 4.875V6.75H5.3125C5.14062 6.75 5 6.89062 5 7.0625V7.6875C5 7.85938 5.14062 8 5.3125 8H15.9375C16.1094 8 16.25 8.14062 16.25 8.3125V8.9375C16.25 9.10938 16.1094 9.25 15.9375 9.25H6.5625C6.39062 9.25 6.25 9.39062 6.25 9.5625V10.1875C6.25 10.3594 6.39062 10.5 6.5625 10.5H14.6875C14.8594 10.5 15 10.6406 15 10.8125V11.4375C15 11.6094 14.8594 11.75 14.6875 11.75H5.3125C5.14062 11.75 5 11.8906 5 12.0625V12.6875C5 12.8594 5.14062 13 5.3125 13H13.4375C13.6094 13 13.75 13.1406 13.75 13.3125V13.9375C13.75 14.1094 13.6094 14.25 13.4375 14.25H7.5V19.25C7.5 21.3203 9.17969 23 11.25 23C13.3203 23 15 21.3203 15 19.25H20C20 21.3203 21.6797 23 23.75 23C25.8203 23 27.5 21.3203 27.5 19.25H29.375C29.7188 19.25 30 18.9688 30 18.625V17.375C30 17.0312 29.7188 16.75 29.375 16.75ZM11.25 21.125C10.2148 21.125 9.375 20.2852 9.375 19.25C9.375 18.2148 10.2148 17.375 11.25 17.375C12.2852 17.375 13.125 18.2148 13.125 19.25C13.125 20.2852 12.2852 21.125 11.25 21.125ZM23.75 21.125C22.7148 21.125 21.875 20.2852 21.875 19.25C21.875 18.2148 22.7148 17.375 23.75 17.375C24.7852 17.375 25.625 18.2148 25.625 19.25C25.625 20.2852 24.7852 21.125 23.75 21.125ZM26.875 13H21.25V8.625H22.9727L26.875 12.5273V13Z'
              fillOpacity='1'
            />
          </g>
          <g id='darkGroup'>
            <path
              id='dark2'
              d='M29.375 16.75H28.75V12.5273C28.75 12.0312 28.5508 11.5547 28.1992 11.2031L24.2969 7.30078C23.9453 6.94922 23.4688 6.75 22.9727 6.75H21.25V4.875C21.25 3.83984 20.4102 3 19.375 3H9.375C8.33984 3 7.5 3.83984 7.5 4.875V6.75H5.3125C5.14062 6.75 5 6.89062 5 7.0625V7.6875C5 7.85938 5.14062 8 5.3125 8H15.9375C16.1094 8 16.25 8.14062 16.25 8.3125V8.9375C16.25 9.10938 16.1094 9.25 15.9375 9.25H6.5625C6.39062 9.25 6.25 9.39062 6.25 9.5625V10.1875C6.25 10.3594 6.39062 10.5 6.5625 10.5H14.6875C14.8594 10.5 15 10.6406 15 10.8125V11.4375C15 11.6094 14.8594 11.75 14.6875 11.75H5.3125C5.14062 11.75 5 11.8906 5 12.0625V12.6875C5 12.8594 5.14062 13 5.3125 13H13.4375C13.6094 13 13.75 13.1406 13.75 13.3125V13.9375C13.75 14.1094 13.6094 14.25 13.4375 14.25H7.5V19.25C7.5 21.3203 9.17969 23 11.25 23C13.3203 23 15 21.3203 15 19.25H20C20 21.3203 21.6797 23 23.75 23C25.8203 23 27.5 21.3203 27.5 19.25H29.375C29.7188 19.25 30 18.9688 30 18.625V17.375C30 17.0312 29.7188 16.75 29.375 16.75ZM11.25 21.125C10.2148 21.125 9.375 20.2852 9.375 19.25C9.375 18.2148 10.2148 17.375 11.25 17.375C12.2852 17.375 13.125 18.2148 13.125 19.25C13.125 20.2852 12.2852 21.125 11.25 21.125ZM23.75 21.125C22.7148 21.125 21.875 20.2852 21.875 19.25C21.875 18.2148 22.7148 17.375 23.75 17.375C24.7852 17.375 25.625 18.2148 25.625 19.25C25.625 20.2852 24.7852 21.125 23.75 21.125ZM26.875 13H21.25V8.625H22.9727L26.875 12.5273V13Z'
              fillOpacity='1'
            />
            <path
              id='dark1'
              d='M41.375 16.75H40.75V12.5273C40.75 12.0312 40.5508 11.5547 40.1992 11.2031L36.2969 7.30078C35.9453 6.94922 35.4688 6.75 34.9727 6.75H33.25V4.875C33.25 3.83984 32.4102 3 31.375 3H21.375C20.3398 3 19.5 3.83984 19.5 4.875V6.75H17.3125C17.1406 6.75 17 6.89062 17 7.0625V7.6875C17 7.85938 17.1406 8 17.3125 8H27.9375C28.1094 8 28.25 8.14062 28.25 8.3125V8.9375C28.25 9.10938 28.1094 9.25 27.9375 9.25H18.5625C18.3906 9.25 18.25 9.39062 18.25 9.5625V10.1875C18.25 10.3594 18.3906 10.5 18.5625 10.5H26.6875C26.8594 10.5 27 10.6406 27 10.8125V11.4375C27 11.6094 26.8594 11.75 26.6875 11.75H17.3125C17.1406 11.75 17 11.8906 17 12.0625V12.6875C17 12.8594 17.1406 13 17.3125 13H25.4375C25.6094 13 25.75 13.1406 25.75 13.3125V13.9375C25.75 14.1094 25.6094 14.25 25.4375 14.25H19.5V19.25C19.5 21.3203 21.1797 23 23.25 23C25.3203 23 27 21.3203 27 19.25H32C32 21.3203 33.6797 23 35.75 23C37.8203 23 39.5 21.3203 39.5 19.25H41.375C41.7188 19.25 42 18.9688 42 18.625V17.375C42 17.0312 41.7188 16.75 41.375 16.75ZM23.25 21.125C22.2148 21.125 21.375 20.2852 21.375 19.25C21.375 18.2148 22.2148 17.375 23.25 17.375C24.2852 17.375 25.125 18.2148 25.125 19.25C25.125 20.2852 24.2852 21.125 23.25 21.125ZM35.75 21.125C34.7148 21.125 33.875 20.2852 33.875 19.25C33.875 18.2148 34.7148 17.375 35.75 17.375C36.7852 17.375 37.625 18.2148 37.625 19.25C37.625 20.2852 36.7852 21.125 35.75 21.125ZM38.875 13H33.25V8.625H34.9727L38.875 12.5273V13Z'
              fillOpacity='1'
            />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default CarDelivery
