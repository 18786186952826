export const openingHours = {
  'MON-FRI': { open: '10:30', close: '22:00' },
  'SAT-SUN': { open: '11:00', close: '22:00' },
}

export const lastOrderTime = {
  'MON-FRI': '21:45',
  'SAT-SUN': '21:45',
}
export const lunchHours = {
  'MON-FRI': { open: '10:30', close: '14:00' },
}
export const lastOrder = {
  'ALL-DAYS': '21:45',
}
export const lastOrderDelivery = {
  'ALL-DAYS': '21:30',
}
