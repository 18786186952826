import styles from '../styles/Header.module.css'
import GoogleReviews from './GoogleReviews'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import Delivery from './Delivery'
import { formatTime } from '@/utils/formatTime'
import { openingHours, lunchHours, lastOrder } from '@/data/timeData'

const ImageCarousel = dynamic(() => import('@/components/ImageCarousel'))
const SearchBarFilter = dynamic(() => import('@/components/SearchBarFilter'))

const Header = () => {
  const { t, i18n } = useTranslation()
  return (
    <header className={styles.headerWrapper}>
      <section className={styles.headerTop}>
        <ImageCarousel />
        <SearchBarFilter />
        <GoogleReviews />
        <section aria-labelledby='aboutArticle' className={styles.openTime}>
          <h2 id='aboutArticle' className={styles.spanDays}>
            {t('Daily')}
          </h2>
          <span className={styles.lunchHour}>
            Lunch <abbr aria-label='från'> fr </abbr> :120kr
          </span>
          <section className={styles.lunchTime}>
            <p>{t('Served Weekdays')}</p>
            <section className={styles.hour}>
              <span className={styles.paddingHour}>{t('MON-FRI')}</span>
              <time dateTime={lunchHours['MON-FRI'].open}>
                {formatTime(lunchHours['MON-FRI'].open, i18n.language)}
              </time>
              <span className={styles.openHoursSpan}>{t('to')}</span>
              <time dateTime={lunchHours['MON-FRI'].close}>
                {formatTime(lunchHours['MON-FRI'].close, i18n.language)}
              </time>
            </section>
            <p>
              <abbr aria-label='Inklusive'>Inkl</abbr>. Sallad & läsk 33cl
            </p>
          </section>
        </section>
        <div className={styles.gluten}>
          <h2>{t('It works well with cards or Swish')}</h2>
          <h3>
            {t('Gluten-free SEK 30 / Vegan cheese SEK 10 / Mozzarella SEK 10')}
            <div className={styles.price}>Extra</div>
          </h3>
          <p className={styles.glutenPara}>
            {t('All pizzas can be served gluten-free, except baked pizzas')}
          </p>
          <p
            className={styles.allergyWarning}
            aria-label='Allergy Warning: If you have any allergies, please contact the staff for assistance.'
          >
            <strong className={styles.allergyStrong}>
              {t('Allergy warning')}
            </strong>
            {t('IF YOU HAVE ANY ALLERGIES PLEASE CONTACT THE STAFF!!')}
          </p>
        </div>
        <section aria-labelledby='openTime' className={styles.openTime}>
          <h2 id='openTime'>{t('Opening hours')}</h2>
          <section className={styles.openHours}>
            <section className={styles.hour}>
              <span className={styles.paddingHour}>{t('Last order')}:</span>
              <time
                className={styles.hour}
                dateTime={openingHours['MON-FRI'].open}
              >
                {formatTime(lastOrder['ALL-DAYS'], i18n.language)}
              </time>
            </section>
            <section className={styles.hour}>
              <span className={styles.paddingHour}>{t('MON-FRI')}:</span>
              <time dateTime={openingHours['MON-FRI'].open}>
                {formatTime(openingHours['MON-FRI'].open, i18n.language)}
              </time>
              <span className={styles.openHoursSpan}>{t('to')}</span>
              <time
                className={styles.hour}
                dateTime={openingHours['MON-FRI'].close}
              >
                {formatTime(openingHours['MON-FRI'].close, i18n.language)}
              </time>
            </section>
            <section className={styles.hour}>
              <span className={styles.paddingHour}>{t('SAT-SUN')}:</span>
              <time
                className={styles.hour}
                dateTime={openingHours['SAT-SUN'].open}
              >
                {formatTime(openingHours['SAT-SUN'].open, i18n.language)}
              </time>
              <span className={styles.openHoursSpan}>{t('to')}</span>
              <time
                className={styles.hour}
                dateTime={openingHours['SAT-SUN'].close}
              >
                {formatTime(openingHours['SAT-SUN'].close, i18n.language)}
              </time>
            </section>
          </section>
        </section>
        <address className={styles.address}>Sparres väg 1 197 33 Bro</address>
      </section>
      <Delivery />
    </header>
  )
}

export default Header
