export function formatTime(timeString, locale = 'sv-SE') {
  const [hour, minute] = timeString.split(':')
  const date = new Date()
  date.setHours(hour, minute, 0, 0)
  return new Intl.DateTimeFormat(locale, {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  }).format(date)
}
